.vertline {
  width: 40px;
  background-color: #124990;
  top: -262px;
  animation:lineup 1.5s forwards;
  position: relative;
  border-radius: 20px;
}

.rightvertline {
  width: 40px;
  top: -452px;
  margin-left: 110px;
  background-color: #124990;
  animation:lineup 2s forwards;
  animation-delay: 2s;
  position: relative;
  border-radius: 20px;
}

.right {
  top: -302px;
  border-bottom: 40px solid #124990;
  animation:lineright 2s forwards;
  position: relative;
  border-radius: 20px;
}


.rightbottom {
  width: 0px;
  border-bottom: 40px solid #124990;
  animation:lineright 2s forwards 1s;
  position: relative;
  top: -222px;
  border-radius: 20px;
}

.dot {
  height: 45px;
  width: 45px;
  margin-top: 90px;
  margin-left: 52px;
  background-color: #124990;
  border-radius: 50%;
  animation: circle 2s forwards;
  animation-delay: 3s;
  opacity: 0;
}

.ntnu {
  font-size: 50px;
  margin-top: 50px;
  margin-left: 2px;
  color: black;
  animation: circle 2s forwards;
  animation-delay: 3s;
  opacity: 0;
}

@keyframes lineup {
  0% {
    height: 0px;
  }
  100%{
    height: 150px;
  }
}

@keyframes lineright {
  0% {
    width: 0px;
  }
  100% {
    width: 150px;
  }
}

@keyframes textAnimation {
  0% {
    height: 0px;
  }
  100% {
    height: 50px;
  }
}

@keyframes circle {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}